<template>
  <footer class="site-footer bg-dark text-light text-center">
    <div class="container">&copy; 2022 SEASTREAK, LLC</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style></style>
