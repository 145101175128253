<template>
  <header class="site-header">
    <nav class="navbar navbar-dark bg-dark">
      <span class="navbar-brand">Seastreak Phone Payment</span>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style></style>
